/* 구글 웹폰트 */
/* @import url('https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
body {
  margin: 0;
  /* 구글 웹폰트 */
  /* font-family: 'Gothic A1', sans-serif; */
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  font-style: normal;
  /* 구글 웹폰트 끝 */
  /*기본 코드*/
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  overflow-x: hidden;
  user-select: none;
}

code {
  font-family: 'Noto Sans KR', sans-serif;
}

input[type='text'],
button {
  font-family: 'Noto Sans KR', sans-serif;
}

input::placeholder {
  font-family: 'Noto Sans KR', sans-serif;
}
/* @media (max-width: 768px) {
  input::placeholder {
    font-size: 14px;
  }
} */

.link {
  text-decoration: none;
  color: inherit; /* 부모 요소의 색상 상속 */
}
